import * as moment from 'moment';

import {
  IMeasurementPoint,
  IDetails,
  IVFluxP30Summary,
  ISampleSummary,
  ISimpleSummary,
  IPowerSummary,
  IPFP30Summary,
} from 'src/app/_shared/classes/measurementpoint.interface';

class Details implements IDetails {
  private mVoltageFluctuationsPrior30Days: IVFluxP30Summary;
  private mHarmonicsPrior30Days: ISampleSummary;
  private mImbalancePrior30Days: ISampleSummary;
  private mPowerFactorPrior30Days: IPFP30Summary;
  private mGroundCurrentPrior30Days: ISimpleSummary;
  private mPower: IPowerSummary;
  private mLastTemperature: ISimpleSummary;
  private mLastCommunication: ISimpleSummary;
  private mSummaryKpi: string;

  constructor(details: IDetails) {
    ({
      voltageFluctuationsPrior30Days: this.mVoltageFluctuationsPrior30Days,
      harmonicsPrior30Days: this.mHarmonicsPrior30Days,
      imbalancePrior30Days: this.mImbalancePrior30Days,
      powerFactorPrior30Days: this.mPowerFactorPrior30Days,
      groundCurrentPrior30Days: this.mGroundCurrentPrior30Days,
      power: this.mPower,
      lastTemperature: this.mLastTemperature,
      lastCommunication: this.mLastCommunication,
      summaryKpi: this.mSummaryKpi,
    } = details);
  }

  get voltageFluctuationsPrior30Days(): IVFluxP30Summary {
    return this.mVoltageFluctuationsPrior30Days;
  }

  get harmonicsPrior30Days(): ISampleSummary {
    return this.mHarmonicsPrior30Days;
  }

  get imbalancePrior30Days(): ISampleSummary {
    return this.mImbalancePrior30Days;
  }

  get powerFactorPrior30Days(): IPFP30Summary {
    return this.mPowerFactorPrior30Days;
  }

  get groundCurrentPrior30Days(): ISimpleSummary {
    return this.mGroundCurrentPrior30Days;
  }

  get power(): IPowerSummary {
    return this.mPower;
  }

  get lastTemperature(): ISimpleSummary {
    return this.mLastTemperature;
  }

  get lastCommunication(): ISimpleSummary {
    return this.mLastCommunication;
  }

  get summaryKpi(): string {
    return this.mSummaryKpi;
  }
}

export class MeasurementPoint implements IMeasurementPoint {
  private mAddress1: string;
  private mAddress2: string;
  private mRoomId?: number;
  private mMeasurementPointId;
  private mMeasurementPointTypeId;
  private mMpId: string;
  private mCreatedWhen: string;
  private mCommissionedWhen: string;
  private mAccountId: number;
  private mLocationId: number;
  private mLocationName: string;
  private mLocationShortname: string;
  private mAccountName: string;
  private mCity: string;
  private mState: string;
  private mCountry: string;
  private mDetails: Details;
  private mMonthsActive: string[] = [];
  private mTimezone: string;
  private mTz: string;
  private mStatus: number;
  private mDriveScanModel: string;
  private mSiteInformation: string;
  private mSerialNumber: string;
  private mLastCommunicationTime: string;
  private mZipCode: string;
  private mMainImageId: number;
  private mIsDemoAccount: number;
  private mIsLocked: number;
  private mSubscriptionType: string;
  private mLongitude: number;
  private mLatitude: number;
  private mPartnerId: number;

  constructor(measurementPoint: IMeasurementPoint) {
    ({
      measurementPointId: this.mMeasurementPointId,
      mpId: this.mMpId,
      createdWhen: this.mCreatedWhen,
      commissionedWhen: this.mCommissionedWhen,
      accountId: this.mAccountId,
      locationId: this.mLocationId,
      locationName: this.mLocationName,
      locationShortname: this.mLocationShortname,
      accountName: this.mAccountName,
      city: this.mCity,
      state: this.mState,
      country: this.mCountry,
      timezone: this.mTimezone,
      measurementPointStatusId: this.mStatus,
      measurementPointTypeId: this.mMeasurementPointTypeId,
      roomId: this.mRoomId,
      driveScanModel: this.mDriveScanModel,
      tz: this.mTz,
      address1: this.mAddress1,
      address2: this.mAddress2,
      zipCode: this.mZipCode,
      siteInformation: this.mSiteInformation,
      serialNumber: this.serialNumber,
      lastCommunicationTime: this.lastCommunicationTime,
      longitude: this.mLongitude,
      latitude: this.mLatitude,
      mainImageId: this.mMainImageId,
      partnerId: this.mPartnerId,
      isDemoAccount: this.mIsDemoAccount,
      isLocked: this.mIsLocked,
      subscriptionType: this.mSubscriptionType,
    } = measurementPoint);
    if (measurementPoint.details) {
      this.mDetails = new Details(measurementPoint.details);
    }

    if (this.createdWhen) {
      const unloggedMonth = moment(this.createdWhen).startOf('month');
      while (unloggedMonth.valueOf() < moment().valueOf()) {
        this.mMonthsActive.unshift(unloggedMonth.format('MMMM YYYY'));
        unloggedMonth.add(1, 'month');
      }
    }
  }

  get monthsActive(): string[] {
    return this.mMonthsActive;
  }

  /**
   * identifies pqube by assigned number
   */
  get measurementPointId(): number {
    return this.mMeasurementPointId;
  }

  get measurementPointTypeId(): number {
    return this.mMeasurementPointTypeId;
  }

  get driveScanModel(): string {
    return this.mDriveScanModel;
  }

  /**
   * identifies pqube by assigned number
   * same value as measurementPointId
   */
  get roomId(): number {
    return this.mRoomId;
  }

  /**
   * identifies pqube by location
   */
  get mpId(): string {
    return this.mMpId;
  }

  set mpId(mpId: string) {
    this.mMpId = mpId;
  }

  get createdWhen(): string {
    return this.mCreatedWhen;
  }

  get commissionedWhen(): string {
    return this.mCommissionedWhen;
  }

  get accountId(): number {
    return this.mAccountId;
  }

  get locationId(): number {
    return this.mLocationId;
  }

  get locationName(): string {
    return this.mLocationName;
  }

  set locationName(locationName: string) {
    this.mLocationName = locationName;
  }

  get locationShortname(): string {
    return this.mLocationShortname;
  }

  set locationShortname(locationShortname: string) {
    this.mLocationShortname = locationShortname;
  }

  set locationId(locationId: number) {
    this.mLocationId = locationId;
  }

  get accountName(): string {
    return this.mAccountName;
  }

  get city(): string {
    return this.mCity;
  }

  set city(city: string) {
    this.mCity = city;
  }

  get state(): string {
    return this.mState;
  }

  set state(state: string) {
    this.mState = state;
  }

  get country(): string {
    return this.mCountry;
  }

  set country(country: string) {
    this.mCountry = country;
  }

  get address1(): string {
    return this.mAddress1;
  }

  set address1(address1: string) {
    this.mAddress1 = address1;
  }

  get address2(): string {
    return this.mAddress2;
  }

  set address2(address2: string) {
    this.mAddress2 = address2;
  }

  get siteInformation(): string {
    return this.mSiteInformation;
  }

  set siteInformation(siteInformation: string) {
    this.mSiteInformation = siteInformation;
  }

  get zipCode(): string {
    return this.mZipCode;
  }

  set zipCode(zipCode: string) {
    this.mZipCode = zipCode;
  }

  get serialNumber(): string {
    return this.mSerialNumber;
  }

  set serialNumber(serialNumber: string) {
    this.mSerialNumber = serialNumber;
  }

  get lastCommunicationTime(): string {
    return this.mLastCommunicationTime;
  }

  set lastCommunicationTime(lastCommunicationTime: string) {
    this.mLastCommunicationTime = lastCommunicationTime;
  }

  get details(): Details {
    return this.mDetails;
  }

  get timezone(): string {
    return this.mTimezone;
  }

  get tz(): string {
    return this.mTz;
  }

  get measurementPointStatusId(): number {
    return this.mStatus;
  }

  set measurementPointStatusId(status) {
    this.mStatus = status;
  }

  get status(): number {
    return this.mStatus;
  }

  get mainImageId(): number {
    return this.mMainImageId;
  }

  set mainImageId(mainImageId: number) {
    this.mMainImageId = mainImageId;
  }

  set isDemoAccount(isDemoAccount) {
    this.mIsDemoAccount = isDemoAccount;
  }

  get isDemoAccount(): number {
    return this.mIsDemoAccount;
  }

  get isLocked(): number {
    return this.mIsLocked;
  }

  get subscriptionType(): string {
    return this.mSubscriptionType;
  }

  get longitude(): number {
    return this.mLongitude;
  }

  set longitude(longitude) {
    this.mLongitude = longitude;
  }

  get latitude(): number {
    return this.mLatitude;
  }

  set latitude(latitude) {
    this.mLatitude = latitude;
  }

  get partnerId(): number {
    return this.mPartnerId;
  }
}

import { Constructor } from '@angular/cdk/table';

export enum MeasurementPointTypeIdEnum {
  QUBEC_SCAN = 1,
  IN_SITE = 2,
  DRIVE_SCAN = 3,
}

export class Object {}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function MpEnum<TBase extends Constructor<any>>(Base: TBase) {
  return class extends Base {
    public MpTypeEnum = MeasurementPointTypeIdEnum;
    public MpStatusEnum = MeasurementPointStatusIdEnum;
  };
}

export enum MeasurementPointStatusIdEnum {
  pendingInstallation = 1,
  installationScheduled = 2,
  collectingData = 4,
  readyForCommissioningReview = 5,
  commissioningInProgress = 6,
  commissioned = 8,
  archived = 9,
  operationPaused = 11,
}
export interface IMeasurementPoint {
  roomId?: number;
  measurementPointId: number;
  mpId: string;
  createdWhen: string;
  commissionedWhen: string;
  accountId: number;
  locationId: number;
  locationName: string;
  locationShortname: string;
  longitude: number;
  latitude: number;
  accountName: string;
  city: string;
  state: string;
  country: string;
  details: IDetails;
  timezone: string;
  tz: string;
  measurementPointStatusId: number;
  measurementPointTypeId?: number;
  driveScanModel?: string;
  address1: string;
  address2: string;
  zipCode: string;
  siteInformation: string;
  serialNumber: string;
  lastCommunicationTime: string;
  kpiColor?: string;
  mainImageId?: number;
  isDemoAccount: number;
  isLocked: number;
  subscriptionType?: string;
  partnerName?: string;
  partnerId?: number;
}

export interface IDetails {
  voltageFluctuationsPrior30Days: IVFluxP30Summary;
  harmonicsPrior30Days: ISampleSummary;
  imbalancePrior30Days: ISampleSummary;
  powerFactorPrior30Days: IPFP30Summary;
  groundCurrentPrior30Days: ISimpleSummary;
  power: IPowerSummary;
  lastTemperature: ISimpleSummary;
  lastCommunication: ISimpleSummary;
  summaryKpi: string;
}

export interface IPowerQualitySummary {
  powerQualityStatusType: string;
  value: any;
}

export interface IVFluxP30Summary extends IPowerQualitySummary {
  value: {
    wiringConfiguration: string;
    P95_RMS: number;
    P05_RMS: number;
    P95_PST: number;
    Num_SevereRVC_events: number;
    samplesThisPeriod: number;
  };
}

export interface ISampleSummary extends IPowerQualitySummary {
  value: {
    samplesThisPeriod: number;
  };
}

export interface IPFP30Summary extends IPowerQualitySummary {
  value: {
    coverage: number;
    count: number;
    samplesThisPeriod: number;
  };
}

export interface IConsumptionP30Summary extends IPowerQualitySummary {
  value: {
    startDate: string;
    endDate: string;
    seconds: number;
    consumption: number;
  };
}

export interface ISimpleSummary extends IPowerQualitySummary {
  value: number;
}

export interface IPowerSummary extends IPowerQualitySummary {
  value: {
    samplesThisPeriod: number;
    samplesPreviourPeriod: number;
    samplesLastYearPeriod: number;
    samplesThisWeek: number;
    samplesPreviousWeek: number;
    energyLastWeek: number;
    energyPreviousWeek: number;
  };
}
